const defaultSettings = [
  {
    config: {},
    icon: 'info',
    translationsKey: 'deliveryDetails',
  },
  {
    config: {
      operator: 'EQ',
      'pdp-attributeId': 'wearTestDaysAttribute',
      value: '90',
    },
    icon: 'circle_check',
    translationsKey: '90Day',
  },
  {
    config: {
      operator: 'IN',
      'pdp-attributeId': 'sizeChart',
      value: 'FWW,FWM,FWY',
    },
    icon: 'circle_check',
    translationsKey: 'oneYear',
  },
  {
    config: {
      operator: 'NOTIN',
      'pdp-attributeId': 'sizeChart',
      value: 'FWW,FWM,FWY',
    },
    icon: 'circle_check',
    translationsKey: 'lifetime',
  },
  {
    config: {},
    icon: 'shipping',
    translationsKey: 'shipping',
  },
];

export const ProductLink = {
  vans: {
    US: [],
    CA: [],
  },
  thenorthface: {
    US: defaultSettings,
    CA: defaultSettings,
  },
};
