const defaultSettings = {
  hideComponent: false,
  isSwapCartItemModal: false,
  showBottomDivider: false,
  showNumeration: false,
  showTopDivider: false,
  showSelectedVariationTitle: false,
  showSizesExpanded: true,
  preselectDefaultSize: false,
};

const vansSettings = {
  ...defaultSettings,
  showNumeration: true,
  showSelectedVariationTitle: true,
  showTopDivider: true,
  preselectDefaultSize: true,
};

const thenorthfaceSettings = {
  ...defaultSettings,
  showSelectedVariationTitle: true,
};

export const ProductAdditionalSizes = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
