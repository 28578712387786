






























































































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import { useUserStore } from '@vf/composables/src/store/user';
import { useCartStore } from '@vf/composables/src/store/cartStore';

import PaymentMethodSummary from '@/components/static/checkout/PaymentMethodSummary.vue';
import CreditCardMicroform from '@/components/static/checkout/CreditCardMicroform.vue';
import AddressPreview from '@/components/static/AddressPreview.vue';
import { replaceAll } from '@/helpers/replaceAll';
import useRootInstance from '@/shared/useRootInstance';
import {
  usePaymentProvider,
  useCardStore,
  usePaymentStore,
} from '@vf/composables';
import { CardType } from '@vf/api-contract';

export default defineComponent({
  name: 'CreditCardSelector',
  components: {
    PaymentMethodSummary,
    AddressPreview,
    CreditCardMicroform,
  },
  setup() {
    const { root } = useRootInstance();

    const userStore = useUserStore(root);
    const cartStore = useCartStore();
    const cardStore = useCardStore();
    const { saveCreditCard } = storeToRefs(cardStore);
    const { loggedIn } = storeToRefs(userStore);

    const selectedCardId = ref<string>('');
    const showAcceptedCreditCards = ref(false);

    const paymentStore = usePaymentStore(root);

    const creditCardAddress = computed(() =>
      cardStore.card?.paymentInstrumentId ? cardStore.card.address : null
    );

    const creditCardSummary = computed(() => {
      const selectedCard = cardStore.card?.paymentInstrumentId
        ? cardStore.card
        : null;
      if (!selectedCard) return null;

      return {
        icon: {
          name: selectedCard.cardType.toLowerCase(),
          width: 57,
          height: 32,
        },
        label:
          replaceAll(root.$t('creditCardSelector.creditCardEnding') as string, {
            cardNumber: selectedCard.maskedNumber.slice(-4),
          }) +
          (selectedCard.expirationMonth && selectedCard.expirationYear
            ? replaceAll(
                root.$t('creditCardSelector.creditCardExpiryDate') as string,
                {
                  dateExpiry: `${
                    selectedCard.expirationMonth
                  }/${selectedCard.expirationYear.toString().slice(-2)}`,
                }
              )
            : ''),
      };
    });

    const creditCardsList = computed(() => {
      return (cartStore.availableCreditCards ?? []).map(({ card }) => {
        const cardNumber = card.maskedNumber.replaceAll('*', 'X');
        const label = `${card.cardType}, ${cardNumber}`;
        return { ...card, label };
      });
    });

    const onCreditCardSelect = (instrumentId) => {
      const selectedCard = (cartStore.availableCreditCards ?? []).find(
        ({ card }) => card.paymentInstrumentId === instrumentId
      );
      cardStore.card = null;
      selectedCardId.value = instrumentId || 'new';
      if (selectedCard) {
        const { address } = selectedCard;
        cardStore.card = {
          ...selectedCard.card,
          cardType: selectedCard.card.cardType as CardType,
          address,
        };
        if (address) cartStore.billingAddress = address;
      }
    };

    onMounted(async () => {
      await paymentStore.getSession();
      const paymentProvider = usePaymentProvider(root);
      await paymentProvider.load();
      await paymentProvider.initService();

      if (cardStore.card?.paymentInstrumentId) {
        onCreditCardSelect(cardStore.card.paymentInstrumentId);
        return;
      }
      const defaultCard =
        creditCardsList.value.find((card) => card.main) ||
        creditCardsList.value[0];
      onCreditCardSelect(defaultCard ? defaultCard.paymentInstrumentId : 'new');
    });

    onBeforeUnmount(() => {
      usePaymentProvider(root).unload();
    });

    return {
      loggedIn,
      showCreditCardSelector: computed(
        () => loggedIn.value && creditCardsList.value.length > 0
      ),
      selectedCardId,
      creditCardsList,
      creditCardAddress,
      creditCardSummary,
      saveCreditCard,
      showAcceptedCreditCards,
      onCreditCardSelect,
      isPaymentProviderKnown: computed(() => !!paymentStore.provider),
    };
  },
});
