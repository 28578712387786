









































































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  PropType,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import { ShoesSizeGender } from '@vf/api-contract';
import ClickOutside from 'vue-click-outside';

type SizeType = number;
type SizeValueType = string; // returned size value is 3 chars length string
type SelectedSizeObject = {
  gender: ShoesSizeGender;
  size: SizeValueType;
};

const DEFAULT_SIZES = [
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12,
  12.5,
  13,
  13.5,
  14,
  14.5,
  15,
  15.5,
  16,
  16.5,
  17,
  17.5,
  18,
];
const WOMEN_SIZE_OFFSET = -1.5;

export default defineComponent({
  name: 'ShoesSizeSelector',
  directives: {
    ClickOutside,
  },
  props: {
    label: { type: String as PropType<string>, default: 'Shoe size' },
    ariaLabel: { type: String as PropType<string>, default: '' },
    placeholder: { type: String as PropType<string>, default: '' },
    mensText: { type: String as PropType<string>, default: 'Male' },
    womensText: { type: String as PropType<string>, default: 'Female' },
    displayFormat: {
      type: String as PropType<string>,
      default: '{{gender}} - {{size}}',
      validator: (value: string) => {
        return !(!value.includes('{{gender}}') && !value.includes('{{size}}'));
      },
    },
    value: {
      type: Object as PropType<SelectedSizeObject>,
      default: null,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const popupItem = ref(null); // prevent click outside event with popupItem.
    const sizes: Ref<SizeType[]> = ref(DEFAULT_SIZES);
    const isPickerVisible: Ref<boolean> = ref(false);

    const selectedGender: Ref<ShoesSizeGender> = ref(ShoesSizeGender.Male);
    const selectedSize: Ref<SizeType> = ref(null);

    watch(
      () => props.value,
      () => setSelected(),
      { deep: true }
    );

    onMounted(() => setSelected());

    const setSelected = () => {
      if (props.value) {
        const size = Number(props.value.size) / 10;
        const shoesGender =
          props.value.gender && props.value.gender !== ShoesSizeGender.Unknown
            ? props.value.gender
            : selectedGender.value;
        selectedGender.value = shoesGender;
        selectedSize.value =
          props.value.gender === ShoesSizeGender.Female
            ? size - WOMEN_SIZE_OFFSET
            : size;
      }
    };

    const displayedValue: ComputedRef<string> = computed(() => {
      if (!selectedSize.value) return null;
      const gender = {
        [ShoesSizeGender.Male]: props.mensText,
        [ShoesSizeGender.Female]: props.womensText,
      };
      return props.displayFormat
        .replace('{{gender}}', gender[selectedGender.value])
        .replace('{{size}}', String(selectedSize.value));
    });

    const openPicker = () => (isPickerVisible.value = true);
    const closePicker = () => (isPickerVisible.value = false);

    const getParsedSize = (size: SizeType): SizeValueType => {
      // Basically women sizes is same as mens minus 1.5 (e.g. 3.5 vs 2)
      const value =
        selectedGender.value === ShoesSizeGender.Female
          ? size + WOMEN_SIZE_OFFSET
          : size;
      return String(10 * value).padStart(3, '0');
    };

    const emitChangeSize = () => {
      const choice: SelectedSizeObject = {
        gender: selectedGender.value,
        size: getParsedSize(selectedSize.value),
      };
      emit('input', choice); // TODO: change v-model to modelValue / update:modelValue according to vue 3 breaking change
    };

    return {
      popupItem,
      isPickerVisible,
      openPicker,
      closePicker,
      sizes,
      selectedGender,
      selectedSize,
      selectGender: (gender: ShoesSizeGender) => {
        selectedGender.value = gender;
        emitChangeSize();
      },
      selectSize: (size: SizeType) => {
        selectedSize.value = size;
        emitChangeSize();
        closePicker();
      },
      displayedValue,
    };
  },
});
