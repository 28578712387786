const defaultSettings = {
  floatingChatButton: {
    backgroundColor: '',
    cssClass: '',
    title: '',
    titleColor: '',
  },
};

const vansSettings = {
  ...defaultSettings,
  backgroundColor: '#717171',
  cssClass: 'live-chat-button',
  title: 'Chat',
  titleColor: '#F7F7F7',
};

export const FloatingChatButton = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: defaultSettings,
    CA: defaultSettings,
  },
};
