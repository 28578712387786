






















































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useAccount, useCheckout, usePaymentMethods } from '@vf/composables';
import type { CheckoutPaymentInfoTranslations } from '@vf/api-contract';
import {
  formatExpirationYear,
  formatExpirationMonth,
} from '@vf/shared/src/utils/helpers';

import PaymentMethod from '@/components/payment/PaymentMethod.vue';
import useRootInstance from '@/shared/useRootInstance';
import { getCardLogo } from '@/helpers';

import { useCard } from '../composable/useCard';

export default defineComponent({
  name: 'CreditCardList',
  components: {
    PaymentMethod,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutPaymentInfoTranslations>,
      default: () => ({}),
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutPaymentInfo;

    const { paymentInstruments } = useAccount(root);

    const { savedCreditCard, paymentMethod, billingAddress } = useCheckout(
      root
    );

    const { arePaymentMethodsDisabled } = usePaymentMethods(root);

    const { setCreditCardPaymentMethod } = useCard({
      savedCreditCard,
      paymentInstruments,
      paymentMethod,
    });

    const cards = computed(() =>
      paymentInstruments.value.cards.map((card) => ({
        code: card.card.paymentInstrumentId,
      }))
    );

    const expirationDate = (card) => {
      const month = `${formatExpirationMonth(card.expirationMonth)}/`;
      const year = formatExpirationYear(card.expirationYear);
      return `${props.translations.cardExpiration} ${month}${year}`;
    };

    const cardEndingNumbers = (card) => {
      return `${props.translations.cardEndingIn} ${card.maskedNumber.substr(
        -4
      )}`;
    };

    const addSeparators = (value, separator = ' ') => {
      return value.match(/.{1,4}/g).join(separator);
    };

    return {
      addSeparators,
      cardEndingNumbers,
      expirationDate,
      billingAddress,
      setCreditCardPaymentMethod,
      cards,
      theme,
      paymentInstruments,
      savedCreditCard,
      formatExpirationYear,
      formatExpirationMonth,
      arePaymentMethodsDisabled,
      getCardLogo,
    };
  },
});
