
























import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { LoyaltyInterestsItemProp } from '../types';

export default defineComponent({
  name: 'VfInterestsCollection',
  props: {
    interests: {
      type: Array as PropType<LoyaltyInterestsItemProp[]>,
      default: () => [],
    },
    heading: {
      type: String,
      default: null,
    },
    subheading: {
      type: String,
      default: null,
    },
    maxSelectedInterests: {
      type: Number,
      default: 3,
    },
  },
  emits: ['interests-change'],
  setup(props, { emit }) {
    const selectedInterestsCount = computed(
      () => props.interests.filter((i) => i.isActive).length
    );

    const handleInterestsChange = (i: LoyaltyInterestsItemProp) => {
      if (
        !i.isActive &&
        selectedInterestsCount.value >= props.maxSelectedInterests
      ) {
        return;
      }

      emit('interests-change', { ...i, isActive: !i.isActive });
    };

    return {
      handleInterestsChange,
    };
  },
});
