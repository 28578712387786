//
//
//
//
//
//
//
//

import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'StoresPage',
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { locale } = root.$i18n;
    const data =
      root.$themeConfig.storeData?.[locale] ||
      root.$themeConfig.storeData?.[
        Object.keys(root.$themeConfig.storeData)[0]
      ];
    const title = data.country.title;
    const metaData = data.country.meta_data;
    return {
      title,
      metaData,
    };
  },
};
