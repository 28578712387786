const defaultSettings = {
  turnOnAddToFavourites: true,
  turnOnAddToFavouritesHeart: true,
  turnOnFindInStore: true,
};

const vansSettings = {
  pdp: {
    favoritesHeartSection: {
      ...defaultSettings,
      turnOnAddToFavourites: false,
      turnOnFindInStore: false,
    },
    findInStoreSection: {
      ...defaultSettings,
      turnOnAddToFavourites: false,
      turnOnAddToFavouritesHeart: false,
    },
  },
  turnOnAddToFavouritesHeart: false,
  turnOnFindInStore: false,
};

export const ProductActionButtons = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: defaultSettings,
    CA: {
      ...defaultSettings,
      turnOnAddToFavouritesHeart: false,
      turnOnFindInStore: false,
    },
  },
};
