//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'ForgotPassword',
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: 'Forgot Password',
    },
    emailLabel: {
      type: String,
      default: 'Email Address',
    },
    placeholder: {
      type: String,
      default: 'Type here',
    },
    validationMessages: {
      type: Object,
      default: () => ({
        requiredError: 'Required field',
        emailError: 'Please provide valid e-mail address.',
      }),
    },
    resetPasswordButton: {
      type: String,
      default: 'Reset Password',
    },
    cancelButton: {
      type: String,
      default: 'Cancel',
    },
    link: {
      type: String,
      default: '/account/sign-in',
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 core redesign - changed variable name due to Sonarqube
    const isCoreRedesign = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesign,
    };
  },
  data() {
    return {
      email: null,
      cancelButtonClassName: '',
    };
  },
  computed: {
    cancelButtonClass() {
      return (
        this.$themeConfig?.forgotPassword?.cancelButtonClass ||
        'vf-button--text'
      );
    },
    inputClass() {
      return this.$themeConfig?.forgotPassword?.inputClass || '';
    },
    themeTitleModifier() {
      return this.$themeConfig?.forgotPassword?.titleModifier || 'title-4';
    },
  },
  mounted() {
    this.$root.$on('clear-forgot-password-email', this.resetEmail);
  },
  beforeDestroy() {
    this.$root.$off('clear-forgot-password-email', this.resetEmail);
  },
  methods: {
    resetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.forgot_password.$el.querySelector('input').focus();
        return;
      }
      this.$emit('reset-password', {
        email: this.email,
      });
    },
    resetEmail() {
      this.email = null;
      // To avoid displaying error after reseting email.
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
});
