

























































import { computed, defineComponent } from '@vue/composition-api';

import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useCheckout } from '@vf/composables';

import { useCard } from '../composable/useCard';
import { usePayments } from '../composable/usePayments';

import SelectPaymentMethod from '../components/SelectPaymentMethod.vue';
import CreditCardList from '../components/CreditCardList.vue';
import CheckoutPaymentVariants from '../../CheckoutPaymentVariants.vue';
import { CheckoutPaymentInfoProps } from '../CheckoutPaymentInfoProps';

export default defineComponent({
  name: 'CheckoutPaymentInfoDefault',
  components: {
    CheckoutPaymentVariants,
    SelectPaymentMethod,
    CreditCardList,
  },
  props: CheckoutPaymentInfoProps,
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutPaymentInfo;

    const { paymentMethod, savedCreditCard } = useCheckout(root);
    const { paymentInstruments } = useAccount(root);

    const {
      showAddNewCreditCardForm,
      handleBackToSavedCards,
      handleNewCreditCard,
      showAddNewCreditCardButton,
      filterCreditCardMethod,
    } = useCard({
      savedCreditCard,
      paymentInstruments,
      paymentMethod,
    });

    const { visiblePaymentMethods } = usePayments();

    const paymentMethods = computed(() =>
      filterCreditCardMethod(visiblePaymentMethods.value)
    );

    return {
      theme,
      showAddNewCreditCardForm,
      handleBackToSavedCards,
      handleNewCreditCard,
      showAddNewCreditCardButton,
      paymentMethod,
      paymentMethods,
      paymentInstruments,
    };
  },
});
