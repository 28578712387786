
































































































import {
  CartProductsTranslations,
  ProductShippingOption,
} from '@vf/api-contract';
import {
  computed,
  defineComponent,
  inject,
  PropType,
  ref,
} from '@vue/composition-api';
import {
  getCacheKeyFromProps,
  isSTS,
  isStandard,
  isPickupOrSts,
} from '@vf/shared/src/utils/helpers';

interface ShipmentSelectorOption {
  code: string;
  label: string;
  storeInfo?: ProductShippingOption['storeInfo'];
  disabled: boolean;
}

export default defineComponent({
  name: 'VfShipmentSelector',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<CartProductsTranslations>,
      required: true,
    },
    shipmentOptions: {
      type: Array as PropType<ProductShippingOption[]>,
      default: () => [],
    },
    productId: String, // Determines which product concerns shipment options
    gift: Boolean, // Determines whether gift option is added or not
    isProsumer: Boolean, // Determines wheter isProdumer or not
  },
  emits: ['change:shipping-option', 'open-shipment-store-modal'],
  setup(props, { emit }) {
    const {
      pickupLabelsMapping,
      showStaticTextInsteadOfShippingLabel,
    } = inject('pickupOptions') as {
      pickupLabelsMapping: Record<string, any>;
      showStaticTextInsteadOfShippingLabel: boolean;
    };

    const isTooltipVisible = ref(false);

    const isIpaAndPickupOrSts = (code: ShipmentSelectorOption['code']) => {
      return props.isProsumer && isPickupOrSts(code);
    };

    const isOptionDisabled = (code: ShipmentSelectorOption['code']) => {
      return (
        (props.gift && !isSTS(code) && !isStandard(code)) ||
        isIpaAndPickupOrSts(code)
      );
    };

    const getPickupLabel = (option: ProductShippingOption) => {
      if (props.isProsumer) return props.translations.pickUpNotAvailable;

      if (option.storeInfo?.id && !option.available) {
        return props.translations.itemNotAvailable;
      }

      const pickupOptions = option.storeInfo?.pickupOptions || [];
      if (!pickupOptions.length) {
        return showStaticTextInsteadOfShippingLabel
          ? props.translations.freePickup
          : option.shippingMethod.label;
      }

      return pickupOptions.map((item) => pickupLabelsMapping[item]).join(' / ');
    };

    const shipmentSelectorOptions = computed<ShipmentSelectorOption[]>(() =>
      props.shipmentOptions.map((option) => {
        const shipmentCode = option.shippingMethod.code;
        const shipmentLabel = showStaticTextInsteadOfShippingLabel
          ? props.translations.shipIt
          : option.shippingMethod.label;

        return {
          ...option,
          code: shipmentCode,
          label: isPickupOrSts(shipmentCode)
            ? getPickupLabel(option)
            : shipmentLabel,
          disabled: !option.available || isOptionDisabled(shipmentCode),
        };
      })
    );

    const selectedShippingOption = computed<ProductShippingOption | undefined>(
      () => props.shipmentOptions.find((option) => option.selected)
    );

    const onShipmentInput = (option) => {
      if (option.disabled) return;

      emit('change:shipping-option', option.storeInfo?.id ?? '');
    };

    const onShipmentSelect = (option: ShipmentSelectorOption, event: Event) => {
      if (option.disabled) return;

      const target = event.target as Element;

      if (
        isTooltipVisible.value ||
        target.classList.contains('vf-tooltip__button') ||
        target.classList.contains('vf-tooltip__cross')
      ) {
        return;
      }

      const storeSelected = option.storeInfo?.id;

      if (!storeSelected && isPickupOrSts(option.code)) {
        emit('open-shipment-store-modal');
      }
    };

    const onShipmentStoreModalOpen = (option) => {
      if (option.disabled) return;

      emit('open-shipment-store-modal', props.productId);
    };

    return {
      isSTS,
      isStandard,
      isPickupOrSts,
      isIpaAndPickupOrSts,
      showStaticTextInsteadOfShippingLabel,
      isTooltipVisible,
      shipmentSelectorOptions,
      selectedShippingOption,
      selectedShippingOptionId: computed(
        () => selectedShippingOption.value?.shippingMethod?.code ?? ''
      ),
      onShipmentInput,
      onShipmentStoreModalOpen,
      onShipmentSelect,
      getPickupLabel,
    };
  },
});
