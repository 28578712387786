
































import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfGiftOptionActions',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object,
      default: () => ({
        save: 'Save',
        remove: 'Remove',
        cancel: 'Cancel',
      }),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
  },
});
