import { render, staticRenderFns } from "./writeReview.vue?vue&type=template&id=10a770ba&scoped=true&"
import script from "./writeReview.vue?vue&type=script&lang=ts&"
export * from "./writeReview.vue?vue&type=script&lang=ts&"
import style0 from "./writeReview.vue?vue&type=style&index=0&id=10a770ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a770ba",
  null
  
)

export default component.exports