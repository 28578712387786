const defaultSettings = {
  showAddToFavourites: false,
};

const vansSettings = {
  ...defaultSettings,
  showAddToFavourites: true,
};

export const ProductAddToCart = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: defaultSettings,
    CA: defaultSettings,
  },
};
