var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reviewsCanLoad)?_c('div',{attrs:{"id":"pr-container"}},[_c('VfContainer',{staticClass:"power-reviews-native-container",attrs:{"full-height":"","full-width":""}},[_c('VfRow',{staticClass:"power-reviews-native-content"},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[(_vm.isLoading)?_c('div',{staticClass:"pr-native-placeholder-container"},[_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":""}},[_c('p',{staticClass:"pr-native-title placeholder"},[_vm._v(" ")]),_vm._v(" "),_vm._l((3),function(i){return _c('div',{key:'pr-native-block-placeholder-' + i,staticClass:"pr-native-block-placeholder placeholder"})})],2)],1)],1)],1):(_vm.productReview && _vm.productReview.reviewsCount > 0)?_c('VfReviews',{attrs:{"translations":_vm.translations,"review":_vm.productReview,"reduced-list-count":_vm.reducedListCount,"show-write-review-cta":false},on:{"click:add-filter":function (value) {
              _vm.addFilter(value);
              _vm.changePage(1);
              _vm.getReviews();
            },"click:remove-filter":function (value) {
              _vm.removeFilter(value);
              _vm.changePage(1);
              _vm.getReviews();
            },"change:sorting":function (value) {
              _vm.setSorting(value);
              _vm.changePage(1);
              _vm.getReviews();
            },"flag-review-submit":_vm.handleFlagReviewSubmit,"click:previous-page":function () {
              _vm.changePage(_vm.paging.currentPageNumber - 1);
              _vm.getReviews(true);
            },"click:next-page":function () {
              _vm.changePage(_vm.paging.currentPageNumber + 1);
              _vm.getReviews(true);
            },"click:helpful-thumb-up":function (value) { return _vm.addReviewVote(
                value.reviewId,
                _vm.ProductReviewVoteType.Helpful,
                value.mediaId
              ); },"click:helpful-thumb-down":function (value) { return _vm.addReviewVote(
                value.reviewId,
                _vm.ProductReviewVoteType.NotHelpful,
                value.mediaId
              ); }}}):_vm._e()],1)],1)],1)],1):_c('div',{staticClass:"error-content-container"},[_c('VfIcon',{attrs:{"icon":"error","size":"xl4"}}),_vm._v(" "),_c('VfHeading',{staticClass:"page-header__title error-title",attrs:{"title":_vm.$t('pagesReviewsId.ReviewsCannotLoadTitle'),"level":3,"subtitle":_vm.$t('pagesReviewsId.ReviewsCannotLoadSubtitle'),"title-modifier":"title-2","subtitle-modifier":"subtitle-4"}}),_vm._v(" "),_c('ThemeButton',{staticClass:"reload-button",attrs:{"color":_vm.$themeSettings.PagesReviewsId.writeReviewButtonType,"size":"full"},on:{"click":_vm.reloadPage}},[_vm._v(_vm._s(_vm.$t('pagesReviewsId.ReviewsCannotLoadCtaLabel'))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }