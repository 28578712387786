

















import { defineComponent, PropType, inject } from '@vue/composition-api';
import type { PhotorankApiMediaItemProduct } from '@vf/api-client/src/api-types';

export default defineComponent({
  name: 'VfSocialNativeModalProductTile',
  props: {
    product: {
      type: Object as PropType<PhotorankApiMediaItemProduct>,
      required: true,
    },
  },
  setup(props) {
    const image = props.product._embedded.base_image.images.mobile;
    const { keepTrackOfTheProduct } = inject<{
      keepTrackOfTheProduct: (product: PhotorankApiMediaItemProduct) => void;
    }>('socialNativeAnalytics');

    return {
      image,
      keepTrackOfTheProduct,
    };
  },
});
