import { render, staticRenderFns } from "./AccordionsExpanderRedesign.vue?vue&type=template&id=212763fe&scoped=true&"
import script from "./AccordionsExpanderRedesign.vue?vue&type=script&lang=js&"
export * from "./AccordionsExpanderRedesign.vue?vue&type=script&lang=js&"
import style0 from "./AccordionsExpanderRedesign.vue?vue&type=style&index=0&id=212763fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212763fe",
  null
  
)

export default component.exports