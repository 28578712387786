const imageSize = {
  width: 90,
  height: 90,
};

export const searchSuggestions = {
  buttonClass: 'vf-button--secondary',
  imageSizes: {
    small: imageSize,
    medium: imageSize,
    large: imageSize,
  },
  showTopSearchesHeading: false,
  markQueryInResultLabels: false,
  showSeeMoreButtonInResults: true,
  showSearchSuggestionsPhrase: true,
  showSearchSuggestionsCloseButton: true,
  showMoreButton: true,
};
