export const header = {
  logoWidth: {
    small: 76, // 4.75rem
    medium: 124, // 7.75rem
    large: 124, // 7.75rem
  },
  logoHeight: {
    small: 31, // '1.9375rem'
    medium: 50, // '3.125rem'
    large: 50, // '3.125rem'
  },
  headerBackground: {
    variantPrimary: {
      start: 'rgba(255,255,255,0.8)',
      end: 'rgba(255,255,255,0)',
      backgroundColor: '#fff',
    },
    variantSecondary: {
      start: 'rgba(0,0,0,0.8)',
      end: 'rgba(0,0,0,0)',
      backgroundColor: '#000000',
    },
  },
  minUtilityNavHeight: 0,
  hasHeaderStyleVariants: false,
  enableVisualSearch: false,
  showClearButtonInMobileSearch: false,
  showClearButtonInDesktopSearch: false,
};
