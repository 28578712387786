//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'StoresCityPage',
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { locale } = root.$i18n;
    const data =
      root.$themeConfig.storeData?.[locale] ||
      root.$themeConfig.storeData?.[
        Object.keys(root.$themeConfig.storeData)[0]
      ];
    const title = data.city.title;
    const metaData = data.city.meta_data;
    const formatLink = data.city.formatLink;
    const storeOrder = data.city.storeOrder;
    const splitDealers = data.city.splitDealers;
    const titleDealerStore = data.city.titleDealerStore;
    const titleVfStore = data.city.titleVfStore;

    return {
      formatLink,
      metaData,
      splitDealers,
      storeOrder,
      title,
      titleDealerStore,
      titleVfStore,
    };
  },
};
