const defaultSettings = {
  writeReviewButtonType: 'secondary',
};

const thenorthfaceSettings = {
  writeReviewButtonType: 'primary',
};

export const PagesReviewsId = {
  vans: {
    US: defaultSettings,
    CA: defaultSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
