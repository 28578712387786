const defaultSettings = {
  hideComponent: false,
  displayAs: 'dropdown',
  isSwapCartItemModal: false,
  preselectDefaultSize: true,
  showDivider: false,
  showModelMeasurements: false,
  showNumeration: false,
  showSelectedVariationTitle: false,
  showSizeChart: true,
  showSizesExpanded: false,
  sizeChartHeight: null,
  sizeChartWidth: null,
  recommendationLink: '',
};

const vansSettings = {
  ...defaultSettings,
  displayAs: 'titles-chips',
  preselectDefaultSize: false,
  showNumeration: true,
  showSizesExpanded: true,
};

const thenorthfaceSettings = {
  displayAs: 'titles-chips',
  preselectDefaultSize: false,
  showSelectedVariationTitle: true,
  showSizeChart: false,
  showSizesExpanded: true,
};

export const ProductSizes = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
