const defaultSettings = {
  dotsNavigationEnabled: true,
  hideControls: false,
  gridLayout: false,
  lazy: false,
  sequenceNumber: 0,
  showBadges: true,
  showWishListIcon: true,
};

const vansSettings = {
  ...defaultSettings,
  sequenceNumber: 1,
  showWishListIcon: false,
};

const thenorthfaceSettings = {
  ...defaultSettings,
  gridLayout: true,
  sequenceNumber: 1,
  showWishListIcon: false,
};

export const ProductGallery = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
