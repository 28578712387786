export const storeData = {
  'en-001': {
    country: {
      title: 'DEALERS / STORE LOCATIONS',
      meta_data: {
        title: 'Locations/Store Directory',
        description: 'Directory of locations. Find a local store near you.',
        keywords: '',
        main_breadcrumb_url_name: 'Select a state',
      },
    },
    state: {
      cities: 'Cities',
      city_title: 'Dealers / Store Locations in {state_name}',
      meta_data: {
        title: 'Locations in {state} ({state_code})',
        description:
          'Visit your local {state}, {state_code} location for products and services.',
        keywords: '',
        main_breadcrumb_url_name: 'Select a state',
      },
    },
    city: {
      title: 'Dealers / Store Locations in {city_state}',
      meta_data: {
        title: 'Locations {city}, {state}',
        description:
          'Visit your local {city_state} store location for products and services.',
        keywords: '',
        main_breadcrumb_url_name: 'Select a state',
      },
    },
    store: {
      store_map_title:
        'STORE DETAILS / STORE LOCATION IN {city}, {province} | {id}',
      store_meta_title: '{name} in {city}, {province}, {postalcode}',
      store_meta_description:
        'Shop at {name} in {city}, {province} for great deals on official WL outerwear, backpacks, footwear, and more.',
      appData: {
        appkey: '266588F2-0EFD-11EB-B231-64499CAB76FA',
        iframe_url: 'https://locations.vans.com/mapwidget.html',
        containedIn: 'Mall/Shopping Center Column Header',
        smsButtonText: 'Send to Mobile',
        emailButtonText: 'Send to Email',
        directionText: 'Get directions',
        openings_text: 'HOURS',
        share_text: 'SHARE',
        info_text: 'INFO',
        pictureText: 'STORE PHOTO',
        mapUrl: 'https://www.google.com/maps/dir',
      },
      store: {
        storeCarriesLabel: 'This Store Carries',
        carries: {
          footwear: 'Footwear',
          apparel: 'Apparel',
          snowboard_boots: 'Snowboard Boots',
          surf: 'Surf',
          pro_skate: 'Skate Footwear',
        },
      },
      logo:
        'https://static.vans.com/ui/www/images/logos/logo.hash-6a49946697efd93990ed1c389cad00d5.svg',
      image: '',
      daysOfWeek: [
        {
          dayOfWeek: 'Monday',
          key: 'm',
        },
        {
          dayOfWeek: 'Tuesday',
          key: 't',
        },
        {
          dayOfWeek: 'Wednesday',
          key: 'w',
        },
        {
          dayOfWeek: 'Thursday',
          key: 'thu',
        },
        {
          dayOfWeek: 'Friday',
          key: 'f',
        },
        {
          dayOfWeek: 'Saturday',
          key: 'sa',
        },
        {
          dayOfWeek: 'Sunday',
          key: 'su',
        },
      ],
    },
  },
};
