
































































import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import type { PhotorankApiMediaItemProduct } from '@vf/api-client/src/api-types';
import type { SocialNativeShopThelookTranslations } from '@vf/api-contract';
import VfSocialNativeModalProductTile from '@vf/ui/components/SocialNative/Molecule.SocialNativeModalProductTile.vue';

export default defineComponent({
  name: 'VfSocialNativeModalProducts',
  components: { VfSocialNativeModalProductTile },
  props: {
    caption: {
      type: String,
      default: '',
    },
    isLoading: Boolean,
    /** Modal visible */
    products: {
      type: Array as PropType<PhotorankApiMediaItemProduct[]>,
      default: () => [],
    },
    translations: {
      type: Object as PropType<SocialNativeShopThelookTranslations>,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isPaginated = ref(true);
    const restrictProducts = computed(() => props.products.slice(0, 6));

    const onInitSwiper = (swiperInstance) => {
      if (swiperInstance.scroller) {
        isPaginated.value = swiperInstance.scroller.pages > 1;
      }
    };

    return {
      onInitSwiper,
      isPaginated,
      itemsLable: computed(() =>
        props.translations.items.replace(
          '{count}',
          '' + restrictProducts.value.length
        )
      ),
      restrictProducts,
    };
  },
});
