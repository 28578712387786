


























import { defineComponent, Ref, ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import WriteReviewWidget from '@/components/WriteReviewWidget.vue';

export default defineComponent({
  components: {
    WriteReviewWidget,
  },
  layout: 'emptyNative',
  setup() {
    const { root } = useRootInstance();
    const { isProductReviewsPageEnabled } = useFeatureFlagsStore();

    const pageCanLoad: Ref<boolean> = ref(true);
    const productId: string | undefined = (root.$route.query
      ?.productId as string)?.toUpperCase();
    const variantId: string | undefined = (root.$route.query
      ?.colorSlug as string)?.toUpperCase();

    if (!isProductReviewsPageEnabled || !productId || !variantId) {
      pageCanLoad.value = false;
      root.$log.warn(
        `[@theme/pages/reviews/writeReview.vue::setup] Page cannot load. isProductReviewsPageEnabled: <${isProductReviewsPageEnabled}>, id: <${productId}>, colorSlug: <${variantId}>`
      );
    }

    const reloadPage = () => {
      root.$router.go(0);
    };

    return {
      pageCanLoad,
      productId,
      variantId,
      reloadPage,
    };
  },
});
