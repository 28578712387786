











































































import { defineComponent, computed } from '@vue/composition-api';

import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useCheckout } from '@vf/composables';

import { useCard } from '../composable/useCard';
import { usePayments } from '../composable/usePayments';

import SelectPaymentMethod from '../components/SelectPaymentMethod.vue';
import CreditCardList from '../components/CreditCardList.vue';

import CheckoutPaymentVariants from '../../CheckoutPaymentVariants.vue';
import { CheckoutPaymentInfoProps } from '../CheckoutPaymentInfoProps';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'CheckoutPaymentInfoSeparately',
  components: {
    CheckoutPaymentVariants,
    SelectPaymentMethod,
    CreditCardList,
  },
  props: CheckoutPaymentInfoProps,
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutPaymentInfo;

    const { paymentMethod, savedCreditCard } = useCheckout(root);
    const { paymentInstruments, areCreditCardsFetched } = useAccount(root);

    const {
      showAddNewCreditCardForm,
      handleBackToSavedCards,
      handleNewCreditCard,
      showAddNewCreditCardButton,
    } = useCard({
      savedCreditCard,
      paymentInstruments,
      paymentMethod,
    });

    const {
      visiblePaymentMethods,
      currentVisiblePaymentMethod,
    } = usePayments();

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const showCreditCardForm = computed(() => {
      return (
        showAddNewCreditCardForm.value ||
        (!paymentInstruments.value.cards.length &&
          (areCreditCardsFetched.value || !loggedIn.value))
      );
    });

    return {
      theme,
      showAddNewCreditCardForm,
      handleBackToSavedCards,
      handleNewCreditCard,
      showAddNewCreditCardButton,
      paymentMethod,
      visiblePaymentMethods,
      currentVisiblePaymentMethod,
      showCreditCardForm,
    };
  },
});
