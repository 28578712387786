const defaultSettings = {
  unitOfMeasure: 'mile',
  distances: [5, 10, 15, 25, 50, 100],
  defaultDistance: 5,
  useLocationClasses: 'full-width justify-center',
  searchButtonClasses: 'full-width justify-center',
  ctaComponentType: 'ThemeButton',
  ctaComponentSize: 'lg',
  ctaComponentColor: 'secondary',
  leftColumnLargeSize: 6,
  showStsMessageIfProductIsAvailable: true,
  imageWidth: 110,
  imageHeight: 137,
};

const thenorthfaceSettings = {
  ...defaultSettings,
  defaultDistance: 25,
  useLocationClasses: 'full-width',
  searchButtonClasses: 'full-width adapt-underline',
  ctaComponentType: 'ThemeLink',
  ctaComponentSize: null,
  ctaComponentColor: 'primary',
  leftColumnLargeSize: 5,
  showStsMessageIfProductIsAvailable: false,
  imageWidth: 65,
  imageHeight: 75,
};

export const pagesFindInStore_Id = {
  vans: {
    US: defaultSettings,
    CA: {
      ...defaultSettings,
      unitOfMeasure: 'km',
    },
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings, // TNF Canada hasn't Find in store functionality
  },
};
