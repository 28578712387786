









































import { defineComponent, PropType, ref } from '@vue/composition-api';
import type { PhotorankApiMediaItem } from '@vf/api-client/src/api-types';

export default defineComponent({
  name: 'VfSocialNativeMedia',
  props: {
    /** Media */
    media: {
      type: Object as PropType<PhotorankApiMediaItem>,
      required: true,
    },
    /** Shop the look translation */
    shopTheLook: {
      type: String,
      default: '$ShopTheLook$',
    },
    ariaLabel: {
      type: String,
      default: '$ShopTheLook$',
    },
  },
  emits: ['shop-the-look'],
  setup(_, { emit }) {
    const btnOnFocus = ref(false);

    const shopTheLookWhenVisible = (event) => {
      const opacity = getComputedStyle(event.target).opacity;
      opacity === '1' && emit('shop-the-look');
    };
    return { btnOnFocus, shopTheLookWhenVisible };
  },
});
