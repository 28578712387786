























































import {
  defineComponent,
  nextTick,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import { useShippingFilter } from '@vf/composables';
import { required } from 'vuelidate/lib/validators';
import useRootInstance from '@/shared/useRootInstance';
import type { BrandifyStoreInfo } from '@vf/api-client';

import VfShippingDestinationsStoreSearch from '@vf/theme/components/static/shippingDestinations/ShippingDestinationsStoreSearch.vue';

export default defineComponent({
  name: 'VfPickupStoreModal',
  components: {
    VfShippingDestinationsStoreSearch,
  },
  setup() {
    const { root } = useRootInstance();
    const {
      closeModal,
      isPickupStoreModalOpen,
      lastSearchInput,
      saveStore,
      selectedStore: filterSelectedStore,
    } = useShippingFilter(root);

    const search = ref(lastSearchInput.value);
    let isGeolocationUsed = !!search.value;
    const selectedStore: Ref<BrandifyStoreInfo | null> = ref(null);
    const stores: Ref<BrandifyStoreInfo[]> = ref([]);
    const refStoreSearch: Ref<InstanceType<
      typeof VfShippingDestinationsStoreSearch
    > | null> = ref(null);

    const onClose = () => {
      closeModal();
    };

    const onSave = (store: BrandifyStoreInfo) => {
      lastSearchInput.value = search.value || store.postalcode;
      saveStore(store);
    };

    const setStore = (store: BrandifyStoreInfo) => {
      selectedStore.value = store;
      if (!root.$viewport.isSmall) onSave(store);
    };

    const setStores = (newStores: BrandifyStoreInfo[]) => {
      stores.value = newStores;
    };

    const geolocation = () => {
      const onError = () => {
        if (refStoreSearch.value) {
          refStoreSearch.value.focusOnInput();
        }
      };
      isGeolocationUsed = true;
      refStoreSearch.value?.searchByGeolocation?.({
        onComplete: () => {
          if (!stores.value.length) onError();
        },
        onError,
      });
    };

    watch(isPickupStoreModalOpen, async (showModal) => {
      if (showModal) {
        selectedStore.value = filterSelectedStore.value;
        await nextTick();
        if (!isGeolocationUsed) {
          return geolocation();
        }
        refStoreSearch.value?.init?.({
          stores: stores.value,
        });
        if (search.value && !stores.value.length) {
          refStoreSearch.value?.searchByPC?.();
        }
      }
    });

    const storesFilter = ({ bopis_enabled }: BrandifyStoreInfo) =>
      bopis_enabled === '1';

    return {
      isPickupStoreModalOpen,
      onClose,
      onSave,
      refStoreSearch,
      search,
      selectedStore,
      setStore,
      setStores,
      stores,
      storesFilter,
    };
  },
  validations() {
    return {
      search: {
        required,
      },
    };
  },
});
