const defaultSettings = {
  showReviewRatio: false,
};

const thenorthfaceSettings = {
  ...defaultSettings,
  showReviewRatio: true,
};

export const ProductRating = {
  vans: {
    US: defaultSettings,
    CA: defaultSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};

// This settings file is a dummy (but working) example of how to add a new setting
// Once templated pages are release and stable we will refactor this settings
// (https://digital.vfc.com/jira/browse/GLOBAL15-59561) in a more efficient way
// because we will not pass the values as props anymore but we will load
// the settings directy in component setup() or in the template. Moreover, some of these
// settings will be removed if we can simplify the logic.
