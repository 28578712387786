




















































import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfPinnedArticle',
  props: {
    articleToDisplay: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    return {
      showArticle: computed(() => !!props.articleToDisplay?.title),
      tag: computed(
        () => props.articleToDisplay?.subjectTaxonomy?.[0]?.value ?? ''
      ),
    };
  },
});
