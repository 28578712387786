

















import { defineComponent } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'StaticPDP',
  key(route) {
    const { root } = useRootInstance();
    const { pdpColorChangeWithoutRefresh } = useFeatureFlagsStore();

    if (pdpColorChangeWithoutRefresh) {
      const key = root.$data.pdpKey || route.path;
      // Clears value because it will be set again on the next swatch click
      root.$data.oldPdpKey = key;
      root.$data.pdpKey = undefined;

      return key;
    }

    return route.path;
  },
  components: {
    VansTemplate: () =>
      import(
        /* webpackChunkName: "pageTemplatesVans" */
        '@/components/static/pdp/pageTemplates/Vans.vue'
      ),
    TheNorthFaceTemplate: () =>
      import(
        /* webpackChunkName: "pageTemplatesTheNorthFace" */
        '@/components/static/pdp/pageTemplates/TheNorthFace.vue'
      ),
  },
  layout: 'defaultStatic',
  transition: 'fade',
  setup() {
    const { root } = useRootInstance();
    const { isStaticPdpEnabled } = useFeatureFlagsStore();

    const pdpComponent: string =
      root.$env.THEME === 'vans' ? 'VansTemplate' : 'TheNorthFaceTemplate';

    return {
      isStaticPdpEnabled,
      pdpComponent,
    };
  },
});
