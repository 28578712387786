var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"vf-notify-me"},[_c('VfHeading',{staticClass:"vf-notify-me__header",attrs:{"title":_vm.translations.notifyBoxTitle,"title-modifier":_vm.titleModifier}}),_vm._v(" "),_c('VfText',{staticClass:"vf-notify-me__textbox",attrs:{"content":_vm.textBox,"modifier":"font-small","html":_vm.htmlTextBox}}),_vm._v(" "),_c('div',{staticClass:"vf-notify-me__size-swatches"},[_vm._t("default",null,{"titleAttrs":{
        titleModifier: _vm.titleModifier,
        class: 'vf-notify-me__label',
      }})],2),_vm._v(" "),_c('VfHeading',{staticClass:"vf-notify-me__label",attrs:{"id":"email-heading","title":_vm.translations.notifyEmailTitle,"title-modifier":_vm.titleModifier}}),_vm._v(" "),_c('VfInput',{staticClass:"vf-notify-me__input",attrs:{"aria-labelledby":"email-heading","name":"subscribe-me","label":"","placeholder":_vm.translations.notifyEmailPlaceholder,"aria-label":_vm.ariaLabel,"valid":!_vm.$v.email.$error,"error-message":!_vm.$v.email.required
        ? _vm.translations.validationMessages.requiredError.replace(
            '{{fieldName}}',
            _vm.translations.notifyEmailTitle.toLowerCase()
          )
        : _vm.translations.validationMessages.emailError},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),(_vm.notificationVisible)?_c('VfText',{staticClass:"vf-notify-me__notification",attrs:{"content":_vm.translations.notificationMessage}}):_vm._e(),_vm._v(" "),_c('VfButton',{staticClass:"vf-notify-me__button vf-button--mobile-full vf-button--medium",attrs:{"disabled":_vm.disabledSubmit},on:{"click":_vm.sendForm}},[_vm._v("\n    "+_vm._s(_vm.translations.notifyButtonText)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }