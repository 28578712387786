













































































import { computed, defineComponent, ref, PropType } from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'VfEmailPreferences',
  props: {
    heading: {
      type: String as PropType<string>,
      default: 'Email Preferences',
    },
    email: {
      type: String as PropType<string>,
      default: '',
    },
    emailLabel: {
      type: String as PropType<string>,
      default: 'Email Address',
    },
    subscribedText: {
      type: String as PropType<string>,
      default: 'SignUp for product news and special promotions.',
    },
    unsubscribedText: {
      type: String as PropType<string>,
      default:
        'You are currently subscribed to email updates. You are on our radar to receive the latest product news and special promotions.',
    },
    unsubscribeInfoText: {
      type: String as PropType<string>,
      default:
        'If you unsubscribe now, you will miss out on all the latest athlete news, expedition updates, cool gear and exclusive offers from The North Face.',
    },
    unsubscribeConfirmationText: {
      type: String as PropType<string>,
      default: 'Are you sure want to unsubscribe?',
    },
    subscribeButtonText: {
      type: String as PropType<string>,
      default: 'Subscribe',
    },
    unsubscribeButtonText: {
      type: String as PropType<string>,
      default: 'Unsubscribe',
    },
    cancelButtonText: {
      type: String as PropType<string>,
      default: 'Cancel',
    },
    showUnsubscribeConfirmation: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isSubscribed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableSubmit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig?.emailPreferences || {};
    const ctaButton = theme.ctaButton || {};
    const submitButton = theme.submitButton || {};
    const cancelButton = theme.cancelButton || {};
    const isShowConfirmUnsubscribe = ref(false);

    const handleSubscribe = () => {
      if (!props.email) return;
      emit('subscribe');
    };

    const handleUnsubscribe = () => {
      if (!props.showUnsubscribeConfirmation) {
        emit('unsubscribe');
        return;
      }
      isShowConfirmUnsubscribe.value = true;
    };

    const handleConfirmUnsubscribe = () => {
      emit('unsubscribe');
      isShowConfirmUnsubscribe.value = false;
    };

    const handleCancelUnsubscribe = () => {
      isShowConfirmUnsubscribe.value = false;
    };

    const subscribeStateText = computed(() => {
      if (props.showUnsubscribeConfirmation && isShowConfirmUnsubscribe.value)
        return null;
      return props.isSubscribed ? props.unsubscribedText : props.subscribedText;
    });

    return {
      theme,
      ctaButton,
      submitButton,
      cancelButton,
      isShowConfirmUnsubscribe,
      handleUnsubscribe,
      handleSubscribe,
      handleConfirmUnsubscribe,
      handleCancelUnsubscribe,
      subscribeStateText,
    };
  },
});
