
















import { StoreCitiesTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'StoresStatePage',
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { locale } = root.$i18n;
    const data =
      root.$themeConfig.storeData?.[locale] ||
      root.$themeConfig.storeData?.[
        Object.keys(root.$themeConfig.storeData)[0]
      ];
    const title = data.state.city_title;
    const metaData = data.state.meta_data;
    const splitDealers = data.state.splitDealers;
    const titleDealerStore = data.state.titleDealerStore;
    const titleVfStore = data.state.titleVfStore;
    const translations: StoreCitiesTranslations = { cities: data.state.cities };
    return {
      metaData,
      splitDealers,
      title,
      titleDealerStore,
      titleVfStore,
      translations,
    };
  },
};
