const defaultSettings = {
  displayAs: 'dropdown',
  hideComponent: false,
  maxQuantity: 5,
  showNumeration: false,
  _states: [],
};

const vansSettings = {
  ...defaultSettings,
  displayAs: 'titles-chips',
  showNumeration: true,
  _states: ['customsPDP'],
};

export const ProductQuantitySelect = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: {},
    CA: {},
  },
};
