





































































import {
  defineComponent,
  ref,
  Ref,
  PropType,
  onMounted,
  computed,
} from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

type Size = {
  label: string;
  value: string;
  available?: boolean;
};

type UpsellsItem = {
  id: number;
  name: string;
  quantity: number;
  price: {
    original: number;
    current: number;
    currency: string;
  };
  custom: boolean;
  upsell: boolean;
  image: {
    url: string;
    alt: string;
  };
  availability: string;
  savedForLater: boolean;
};

export default defineComponent({
  name: 'VfUpsells',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Selector heading template */
    headingTemplate: {
      type: String as PropType<string>,
      default: '',
    },
    /** Upsells type */
    type: {
      type: String as PropType<'singleSized' | 'multiSized'>,
      default: 'singleSized',
    },
    /** Availables sizes for a multi-sized product */
    availableSizes: {
      type: Array,
      default: () => [],
    },
    /** Upselled products collection */
    upsells: {
      type: Array as PropType<UpsellsItem[]>,
      default: (): UpsellsItem[] => [],
    },
    /** Button modifier classes */
    buttonStyle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Button label text */
    buttonText: {
      type: String as PropType<string>,
      default: '',
    },
    /** Aria-label for selector */
    ariaLabel: {
      type: String as PropType<string>,
      default: '',
    },
    imageWidth: {
      type: Number,
      default: 65,
    },
    imageHeight: {
      type: Number,
      default: 65,
    },
    /** Default option for a multi sized upsell */
    defaultOption: {
      type: String,
      default: '',
    },
    /** Out of Stock label, for example: "Out of Stock" */
    outOfStock: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const selectedValue: Ref<UpsellsItem> = ref(null);
    const selectedSize: Ref<Size> = ref(null);
    const isMultiSizedSelectValid: Ref<boolean> = ref(true);

    onMounted(() => {
      selectedValue.value = props.upsells?.[0] ?? null;
      selectedSize.value = props.availableSizes.find(
        (size: Size) => size.value === props.defaultOption
      ) as Size;
    });

    const changeSelected = (value: string) => {
      selectedValue.value = props.upsells.find(
        (item) => String(item.id) === value
      );
    };

    const changeSize = (value: string) => {
      if (!isMultiSizedSelectValid.value) {
        isMultiSizedSelectValid.value = true;
      }

      selectedSize.value = props.availableSizes.find(
        (size: Size) => String(size.value) === value
      ) as Size;
    };

    const extractProductPrice = (item) => {
      const { price } = item || {};

      if (price) {
        return `${price.currency} ${price.original}`;
      }

      return '';
    };

    const extractOptionName = (item) => {
      const { name = '' } = item || {};

      return `${name} ${extractProductPrice(item)}`;
    };

    const title = computed(() => {
      return props.type === 'multiSized'
        ? props.headingTemplate
            .replace('{0}', selectedValue.value?.name || '')
            .replace('{1}', extractProductPrice(selectedValue.value))
        : props.headingTemplate;
    });

    const addToCart = () => {
      switch (props.type) {
        case 'multiSized':
          if (!selectedSize.value) {
            isMultiSizedSelectValid.value = false;
          } else {
            emit('click-add-to-cart', selectedSize.value);
          }
          break;
        case 'singleSized':
          emit('click-add-to-cart', selectedValue.value);
          break;
      }
    };

    const addToCartButtonDisabled = computed(
      () =>
        props.type === 'multiSized' &&
        selectedSize.value &&
        !selectedSize.value.available
    );

    return {
      title,
      extractOptionName,
      extractProductPrice,
      selectedValue,
      changeSelected,
      changeSize,
      selectedSize,
      addToCart,
      isMultiSizedSelectValid,
      addToCartButtonDisabled,
    };
  },
});
