




import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  provide,
  watch,
} from '@vue/composition-api';
import {
  ROUTES,
  useAuthentication,
  useCart,
  useGtm,
  useI18n,
  useRequestTracker,
  useSaveForLater,
  useSignInToStore,
} from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';
import ss from '@vf/composables/src/utils/sessionStorage';
import { CheckoutStepNumber } from '@vf/composables/src/types/gtm';
import useRootInstance from '@/shared/useRootInstance';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useCartStore } from '@vf/composables/src/store/cartStore';

export default defineComponent({
  key() {
    return 'cms-page';
  },
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { dispatchEvent, dispatchErrorEvent } = useGtm(root);
    const { onBaseDone } = useRequestTracker(root);

    const {
      cart,
      cartId,
      loadCart,
      keepCartAlive,
      updateCartForBopis,
      getProductRelatedFlashMessages,
      showExpiredSharedCartError,
      setMiniCart,
    } = useCart(root);
    const { getSaveForLaterItems } = useSaveForLater(root);
    const { getConsumerId } = useAuthentication(root);
    const { localePath } = useI18n(root);
    const { employeeConnected } = useSignInToStore(root);
    const {
      isBopisEnabled,
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
    } = storeToRefs(useFeatureFlagsStore());
    const isCartPage = computed(() => root.$route.path.endsWith(ROUTES.CART()));

    // TODO: GLOBAL15-56318 Remove after checkout redesign
    provide('isCheckoutRedesignEnabled', isCheckoutRedesignEnabled);

    // TODO: GLOBAL15-61059 remove after core redesign
    provide('isCoreRedesignEnabled', isCoreRedesignEnabled);

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const isCheckoutShippingPage = computed(() =>
      root.$route.path.endsWith(ROUTES.CHECKOUT_SHIPPING())
    );

    const isCheckoutOrderStatus = computed(() =>
      root.$route.path.endsWith(ROUTES.CHECKOUT_ORDER_STATUS())
    );

    if (isCheckoutOrderStatus.value && !useCheckoutStore().order) {
      root.$router.replace(localePath(ROUTES.HOME()));
    }

    const dispatchShippingEvent = (): void => {
      nextTick(() => {
        dispatchEvent({
          eventName: 'checkout',
          overrideAttributes: {
            step: CheckoutStepNumber.SHIPPING,
          },
        });
      });
    };

    const dispatchOrderStatusEvent = (): void => {
      const eventName = 'checkoutCompletion';
      try {
        const trackedOrders = JSON.parse(ss.getItem('trackedOrders'));
        const { order } = useCheckoutStore();
        if (order?.orderNumber && !trackedOrders?.[order.orderNumber]) {
          onBaseDone(() => {
            nextTick(() => {
              dispatchEvent({ eventName });
              ss.setItem(
                'trackedOrders',
                JSON.stringify({
                  ...trackedOrders,
                  [order?.orderNumber]: true,
                })
              );
            });
          });
        }
      } catch (err) {
        dispatchErrorEvent(eventName, err);
        root.$log.error(
          `[@theme/pages/index::dispatchOrderStatusEvent] [GTM] ${eventName} event was not fired.`,
          err
        );
      }
    };

    // TODO: Remove it after GLOBAL15-56318
    const fetchCartData = async (): Promise<void> => {
      if (!isClient || !isCheckoutShippingPage.value || isCartPage.value) {
        return;
      }

      if (!cartId.value) {
        root.$router.push(localePath(ROUTES.HOME()));
        return;
      }

      await loadCart({
        isBackgroundRequest: false,
        isTemporary: false,
        inventorySupplyCheck: true,
      });

      // Get flash errors related to products, to prevent redirection to home page and show these errors for the customer
      const productRelatedFlashMessages = getProductRelatedFlashMessages();
      const { customerFacingFlashes } = storeToRefs(useCartStore());

      if (
        !cart.value.totalItems &&
        !productRelatedFlashMessages.length &&
        !customerFacingFlashes.value.length
      ) {
        root.$router.push(localePath(ROUTES.HOME()));
        return;
      }
    };

    // TODO: Remove it after GLOBAL15-56318
    if (isClient) {
      /**
       * Trigger cart related logic when cart page is reached
       */
      watch(
        isCartPage,
        async (newValue) => {
          if (newValue) {
            if (!employeeConnected.value) {
              getSaveForLaterItems();
            }
            showExpiredSharedCartError();

            if (!cartId.value) {
              await loadCart({
                isBackgroundRequest: false,
                isTemporary: false,
                inventorySupplyCheck: true,
              });
              return;
            }

            if (isBopisEnabled) {
              updateCartForBopis();
            } else {
              keepCartAlive();
            }
            setMiniCart(false);
          }
        },
        { immediate: true }
      );

      /**
       * Trigger order success related logic when checkout order status page is reached
       */
      watch(
        isCheckoutOrderStatus,
        (newValue) => {
          if (newValue) {
            dispatchOrderStatusEvent();
          }
        },
        { immediate: true }
      );

      if (isCheckoutShippingPage.value) {
        onBaseDone(() => {
          // Wait for CmsPage to push the page load events
          dispatchShippingEvent();
        });
      }

      if (root.$config.areIpaWranxEnabled) {
        watch(
          getConsumerId,
          (currentValue, prevValue) => {
            if (loggedIn.value && currentValue && currentValue !== prevValue) {
              const { getSegments } = useAuthentication(root);
              getSegments();
            }
          },
          { immediate: true }
        );
      }
    }

    // TODO: Remove it after GLOBAL15-56318
    onBeforeMount(async () => {
      await fetchCartData();

      root.$router.afterEach(() => {
        root.$launchDarkly.fetchAllFlags();
      });
    });

    // TODO: Remove it after GLOBAL15-56318
    watch(isCheckoutShippingPage, async (newValue) => {
      if (newValue) {
        dispatchShippingEvent();
        await fetchCartData();
      }
    });

    return {
      isCheckoutShippingPage,
    };
  },
});
