





























































import {
  defineComponent,
  computed,
  ComputedRef,
  ref,
  watch,
} from '@vue/composition-api';
import debounce from '@vf/shared/src/utils/helpers/debounce';

export default defineComponent({
  name: 'QuantityPicker',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 10,
    },
    maxAllowed: {
      type: Number,
      default: 10,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const iconSize = '13px';

    const clamp = (n: number, min: number, max: number) =>
      Math.min(max, Math.max(min, n));

    const iconColor = (value: boolean) => {
      return props.disabled || value
        ? '#ccc' /* var(--c-disabled) */
        : '#2a2a2a'; /* var(--c-dark) */
    };
    const resultMaxQty: ComputedRef<number> = computed(() =>
      props.max < props.maxAllowed ? props.max : props.maxAllowed
    );
    let inputValue = ref(props.value);
    watch(
      () => props.value,
      (newVal) => (inputValue.value = newVal)
    );
    const pickerError: ComputedRef<string> = computed(() => {
      return props.errorMessage.includes('{quantity}')
        ? props.errorMessage.replace('{quantity}', String(resultMaxQty.value))
        : props.errorMessage;
    });

    const previousValue = ref(resultMaxQty.value);
    // add this variable in scope of GLOBAL15-31296; Should be removed after component refactor
    const valueWasNotChanged = ref(true);
    const changeValueHandler = (
      value: number | string,
      type?: string
    ): number => {
      valueWasNotChanged.value = false;
      if (!props.disabled) {
        let valueChanged = true;
        let v = value === '' ? props.min : Number(value);
        const minus = type === 'isMinus';
        const plus = type === 'isPlus';

        if (v > props.min && v < resultMaxQty.value)
          v = minus ? v - 1 : plus ? v + 1 : v;
        else if (v < props.min) {
          valueChanged = false;
          context.emit('reset');
          v = props.min;
        } else if (v > resultMaxQty.value) v = resultMaxQty.value;
        else if (v === props.min) v = plus ? v + 1 : props.min;
        else if (v === resultMaxQty.value)
          v = minus ? v - 1 : resultMaxQty.value;
        // Overwrite value provided by the user if it is invalid
        if (+v !== +inputValue.value) {
          inputValue.value = v;
        }
        if (valueChanged) {
          context.emit('change', Number(v));
        }
        if (type) {
          previousValue.value = Number(v);
        }
        return Number(v);
      }
    };
    const handleChange = debounce((e: Event) => {
      valueWasNotChanged.value = false;
      const target = e.target as HTMLInputElement;
      const value = +target.value;

      if (value !== inputValue.value && target.value !== '') {
        previousValue.value = value || 1;
        target.value = clamp(value, 0, resultMaxQty.value) + '';
      }

      if (e.type == 'blur') {
        if (target.value == '') {
          target.value = '1';
        }
      }

      changeValueHandler(target.value);
    }, 300);
    const overLimit = computed(
      () => props.value >= resultMaxQty.value && valueWasNotChanged.value
    );

    const showErrorMessage = computed(() => {
      return (
        !!pickerError.value &&
        (overLimit.value || previousValue.value > resultMaxQty.value)
      );
    });

    const reset = () => {
      inputValue.value = props.value;
      valueWasNotChanged.value = true;
    };

    return {
      iconSize,
      iconColor,
      inputValue,
      changeValueHandler,
      pickerError,
      handleChange,
      overLimit,
      showErrorMessage,
      resultMaxQty,
      reset,
      previousValue,
    };
  },
});
