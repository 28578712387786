const defaultSettings = {
  notificationPersistent: false,
};

export const DiscountNotification = {
  vans: {
    US: defaultSettings,
    CA: defaultSettings,
  },
  thenorthface: {
    US: defaultSettings,
    CA: defaultSettings,
  },
};
