var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showArticle)?_c('VfContainer',{staticClass:"pinned-article",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"nested":""}},[_c('VfRow',{staticClass:"article-grid__header"},[_c('VfColumn',{attrs:{"small":"12","medium":"6","large":"6","content-position":{
        small: 'middle_center',
        medium: 'middle_center',
        large: 'middle_center',
      }}},[_c('UiLink',{attrs:{"to":_vm.articleToDisplay.link}},[_c('VfImage',{attrs:{"src":_vm.articleToDisplay.image}})],1)],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"6","large":"6","content-position":{
        small: 'middle_left',
        medium: 'middle_left',
        large: 'middle_left',
      }}},[_c('UiLink',{staticClass:"pinned-article__title",attrs:{"to":_vm.articleToDisplay.link}},[_c('VfText',{attrs:{"content":_vm.articleToDisplay.title,"modifier":"title-article"}})],1),_vm._v(" "),(_vm.articleToDisplay.richText || _vm.articleToDisplay.text)?_c('VfText',{staticClass:"pinned-article__subtitle",attrs:{"content":_vm.articleToDisplay.richText || _vm.articleToDisplay.text,"html":!!_vm.articleToDisplay.richText}}):_vm._e(),_vm._v(" "),(_vm.tag)?_c('VfText',{staticClass:"article-tile__tag",attrs:{"modifier":"article-tag","content":_vm.tag}}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }