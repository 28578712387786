const defaultSettings = {
  headingLevel: 1,
  displayBadgeInsideTitle: true,
  textAlign: { small: 'left', medium: 'left', large: 'left' },
};

const vansSettings = {
  ...defaultSettings,
  displayBadgeInsideTitle: false,
};

const thenorthfaceSettings = {
  ...defaultSettings,
  textAlign: { small: 'center', medium: 'left', large: 'left' },
};

export const ProductName = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
