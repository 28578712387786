
import { defineComponent } from '@vue/composition-api';
import { treeRenderer } from './cmsUtils';

export default defineComponent({
  name: 'CmsDynamicSlot',
  props: {
    data: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    contextKey: {
      type: String,
      default: '',
    },
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: `vf-page-dynamic-slot__${this.name}`,
      },
      [
        [this.data].map((element) => {
          return createElement(
            'div',
            {},
            treeRenderer(createElement, element, this.contextKey, this.$root)
          );
        }),
      ]
    );
  },
});
