

























import { computed, defineComponent } from '@vue/composition-api';

import { PaymentMethodCode } from '@vf/api-client';
import { useAccount, useCheckout, usePaymentMethods } from '@vf/composables';

import useRootInstance from '@/shared/useRootInstance';
import PaymentMethod from '@/components/payment/PaymentMethod.vue';

import { getCardLogo, getPaymentIcon, getPaymentLabel } from '@/helpers';

import { useCard } from '../composable/useCard';
import { CheckoutPaymentInfoProps } from '../CheckoutPaymentInfoProps';

export default defineComponent({
  name: 'SelectPaymentMethod',
  components: {
    PaymentMethod,
  },
  props: {
    ...CheckoutPaymentInfoProps,
    paymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutPaymentInfo;

    const { paymentInstruments } = useAccount(root);
    const { savedCreditCard, paymentMethod } = useCheckout(root);

    const { arePaymentMethodsDisabled } = usePaymentMethods(root);

    const { showAddNewCreditCardForm } = useCard({
      savedCreditCard,
      paymentInstruments,
      paymentMethod,
    });

    const getPaymentMethodIcon = (code: string, type = '') => {
      const logo = getCardLogo(type);
      // don't show icon if we could find logo for this type
      // we would show logo later
      if (logo) {
        return '';
      }
      return getPaymentIcon(props.paymentMethodsIcons, code).icon;
    };

    const getPaymentMethodImage = (code: string, type = '') => {
      const logo = getCardLogo(type);

      // only if we could find logo for this type
      // if logo is not found, icon will be used
      return logo || getPaymentIcon(props.paymentMethodsIcons, code).image;
    };

    const paymentMethodTooltip = computed(() => ({
      [PaymentMethodCode.PAYPAL]: props.translations.paypalTooltip,
      [PaymentMethodCode.APPLEPAY]: props.applePayTranslations.tooltip,
    }));

    const paymentMethodTooltipAriaLabel = computed(() => ({
      [PaymentMethodCode.PAYPAL]: props.translations.paypalTooltipAriaLabel,
    }));

    const displayPaymentLabel = (code) =>
      !theme.hiddenLabels.includes(code.toLowerCase());

    return {
      paymentMethod,
      showAddNewCreditCardForm,
      paymentInstruments,
      displayPaymentLabel,
      paymentMethodTooltip,
      paymentMethodTooltipAriaLabel,
      getPaymentMethodIcon,
      getPaymentMethodImage,
      theme,
      getPaymentLabel: (method) => getPaymentLabel(props.paymentLabels, method),
      arePaymentMethodsDisabled,
    };
  },
});
