var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-social-native-sign-in"},[_c('form',{staticClass:"vf-social-native-sign-in__form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();}}},[_c('VfHeading',{staticClass:"vf-social-native-sign-in__heading",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"level":2,"title":_vm.translations.title,"title-modifier":"title-4"}}),_vm._v(" "),_c('VfText',{staticClass:"vf-social-native-sign-in__subcopy",attrs:{"html":"","content":_vm.translations.subCopy}}),_vm._v(" "),_c('VfInput',{staticClass:"vf-social-native-sign-in__input",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"required":"","data-protected":"","label":_vm.translations.displayName,"name":((_vm.translations.displayName) + "-" + (_vm.translations.title)),"error-message":_vm.translations.requiredErrorMessage.replace(
          '{{fieldName}}',
          _vm.translations.displayName.toLowerCase()
        ),"valid":!_vm.$v.name.$error},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}}),_vm._v(" "),_c('VfInput',{staticClass:"vf-social-native-sign-in__input",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"required":"","data-protected":"","label":_vm.translations.email,"name":((_vm.translations.email) + "-" + (_vm.translations.title)),"error-message":!_vm.$v.email.required
          ? _vm.translations.requiredErrorMessage.replace(
              '{{fieldName}}',
              _vm.translations.email.toLowerCase()
            )
          : _vm.translations.emailErrorMessage,"valid":!_vm.$v.email.$error},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_vm._v(" "),_c('VfCheckbox',{staticClass:"vf-social-native-sign-in__checkbox",attrs:{"name":"vf-social-native-sign-in-terms","label":_vm.translations.agreementLabel,"valid":!_vm.$v.isTermsConfirmed.$error,"error-message":_vm.translations.termsError},on:{"change":function($event){return _vm.$v.isTermsConfirmed.$touch()},"blur":function($event){return _vm.$v.isTermsConfirmed.$touch()}},model:{value:(_vm.isTermsConfirmed),callback:function ($$v) {_vm.isTermsConfirmed=$$v},expression:"isTermsConfirmed"}}),_vm._v(" "),(_vm.createUserError)?_c('p',{staticClass:"vf-social-native-sign-in__error"},[_vm._v("\n      "+_vm._s(_vm.translations.createUserError)+"\n    ")]):_vm._e(),_vm._v(" "),_c('ThemeButton',{staticClass:"vf-social-native-sign-in__button vf-button--mobile-full",attrs:{"color":"primary","size":"sm","disabled":_vm.$v.$invalid,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"type":"submit"},on:{"click":_vm.signIn}},[_vm._v("\n      "+_vm._s(_vm.translations.next)+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }