//
//
//
//

import { onBeforeUnmount } from '@vue/composition-api';
import { useCategory } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
export default {
  layout: 'cms/cmsDefault',
  setup(props) {
    const { root } = useRootInstance();
    const { resetPagination } = useCategory(root, props.contextKey);
    const page = root.$route.query.page;
    if (!page) resetPagination();
    else if (page == 1) {
      delete root.$route.query.page;
      const query = root.$route.query;
      root.$router.replace({ query });
    }

    onBeforeUnmount(() => {
      let newUrl = `${window.location.origin}${window.location.pathname}`;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('page');
      if (urlParams.toString()) newUrl += `?${urlParams.toString()}`;
      window.history.replaceState(
        {
          ...(window.history.state || {}),
          path: newUrl,
        },
        '',
        newUrl
      );
      resetPagination();
    });
  },
};
