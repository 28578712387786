const defaultSettings = {
  buttonComponent: '',
  buttonSize: 'md',
  buttonStyle: { color: 'primary' },
  enabled: true,
  icon: 'customize',
  iconPosition: '',
  isFullWidth: false,
  text: '',
  textCustomClasses: '',
  underline: false,
  _states: [],
};

const vansSettings = {
  ...defaultSettings,
  buttonComponent: 'ThemeButton',
  buttonSize: 'sm',
  buttonStyle: { color: 'secondary', variant: 'border' },
  icon: 'customize_board',
  iconPosition: 'right',
  _states: ['not-customsPDP'],
};

export const ProductCustomize = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: {},
    CA: {},
  },
};
