import { DiscountNotification } from './components/smart/shared/DiscountNotification';
import { FloatingChatButton } from './components/smart/shared/FloatingChatButton';
import { MonetateProductRecommendations } from './components/smart/monetate/MonetateProductRecommendations';
import { pagesFindInStore_Id } from './pages/find-in-store/_id';
import { PdpProductColors } from './components/smart/pdp/PdpProductColors';
import { ProductActionButtons } from './components/smart/pdp/ProductActionButtons';
import { ProductAddToCart } from './components/smart/shared/ProductAddToCart';
import { ProductAdditionalSizes } from './components/smart/pdp/ProductAdditionalSizes';
import { ProductCustomize } from './components/smart/pdp/ProductCustomize';
import { ProductLink } from './components/smart/pdp/ProductLink';
import { ProductGallery } from './components/smart/pdp/ProductGallery';
import { ProductName } from './components/smart/pdp/ProductName';
import { ProductQuantitySelect } from './components/smart/pdp/ProductQuantitySelect';
import { ProductRating } from './components/smart/pdp/ProductRating';
import { ProductSizes } from './components/smart/pdp/ProductSizes';
import { PagesReviewsId } from './pages/reviews/_id';

export const getSettings = (brand, countryCode) => {
  return {
    // Pages
    pagesFindInStore_Id: pagesFindInStore_Id[brand][countryCode],
    PagesReviewsId: PagesReviewsId[brand][countryCode],

    // Components
    DiscountNotification: DiscountNotification[brand][countryCode],
    FloatingChatButton: FloatingChatButton[brand][countryCode],
    MonetateProductRecommendations:
      MonetateProductRecommendations[brand][countryCode],
    PdpProductColors: PdpProductColors[brand][countryCode],
    ProductActionButtons: ProductActionButtons[brand][countryCode],
    ProductAddToCart: ProductAddToCart[brand][countryCode],
    ProductAdditionalSizes: ProductAdditionalSizes[brand][countryCode],
    ProductCustomize: ProductCustomize[brand][countryCode],
    ProductGallery: ProductGallery[brand][countryCode],
    ProductLink: ProductLink[brand][countryCode],
    ProductName: ProductName[brand][countryCode],
    ProductQuantitySelect: ProductQuantitySelect[brand][countryCode],
    ProductRating: ProductRating[brand][countryCode],
    ProductSizes: ProductSizes[brand][countryCode],
  };
};
