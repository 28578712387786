const defaultSettings = {
  displayedProductsLarge: 6,
  displayedProductsMedium: 4,
  displayedProductsSmall: 2,
  showAsPeek: {
    large: false,
    medium: false,
    small: false,
  },
  slideFullRow: true,
  shouldSaveOnlyColor: false,
};

const thenorthfaceSettings = {
  displayedProductsLarge: 5,
  displayedProductsMedium: 5,
  displayedProductsSmall: 2,
  showAsPeek: {
    large: true,
    medium: true,
    small: true,
  },
  slideFullRow: false,
  shouldSaveOnlyColor: true,
};

export const MonetateProductRecommendations = {
  vans: {
    US: defaultSettings,
    CA: defaultSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
