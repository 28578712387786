


































































































import {
  defineComponent,
  PropType,
  computed,
  ref,
  inject,
} from '@vue/composition-api';
import { UpsellsItem } from '@vf/api-contract';

export default defineComponent({
  name: 'VfUpsellsCheckbox',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    upsell: {
      type: Object as PropType<UpsellsItem>,
      default: () => ({}),
    },
    upsellTitle: {
      type: String,
      default: '',
    },
    addToCartText: {
      type: String,
      default: '',
    },
    detailsButtonText: {
      type: String,
      default: '',
    },
  },
  emits: ['update:selected', 'details-button-click'],
  setup(props, { emit, root }) {
    // Remove in GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');
    const isChecked = ref(props.selected);
    const productPrice = computed(
      () =>
        (props.upsell.price &&
          root.$formatPrice(
            props.upsell.price.current,
            props.upsell.price.currency
          )) ||
        ''
    );
    const PRICE_MARKER = '{{price}}';
    const showPrice = computed(
      () => !!props.upsellTitle?.includes(PRICE_MARKER)
    );
    const splitTitle = computed(() => {
      if (!props.upsellTitle) return [];
      return showPrice.value
        ? props.upsellTitle.split(PRICE_MARKER)
        : [props.upsellTitle];
    });
    const titleBeforePrice = computed(() => splitTitle.value?.[0]);
    const titleAfterPrice = computed(() => splitTitle.value?.[1]);
    const handleChange = (value) => emit('update:selected', value);
    return {
      productPrice,
      isChecked,
      handleChange,
      showPrice,
      titleBeforePrice,
      titleAfterPrice,
      isVansPdpRedesignEnabled,
    };
  },
});
