




import { defineComponent, useAsync, ssrRef } from '@nuxtjs/composition-api';
import { apiClientFactory } from '@vf/api-client';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  layout: 'empty',
  setup() {
    const { root } = useRootInstance();
    const statusMessage = ssrRef('Failed!!', 'health-check-probe-status');
    const { probeApiMiddleware } = apiClientFactory(root);
    useAsync(async () => {
      const probe = await probeApiMiddleware();
      // Check if the API is running
      // Nuxt should throw its own error if something happens
      // on the API side. This is just in case something gets by
      if (probe.status === 200) {
        statusMessage.value = 'Success!';
      } else {
        throw new Error('API Middleware is not responding');
      }
    });
    return {
      statusMessage,
    };
  },
});
