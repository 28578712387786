







import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import SignInToStore from '@/components/smart/buyInStore/SignInToStore.vue';

export default {
  name: 'PoslogonPage',
  key() {
    return useFeatureFlagsStore().isCoreRedesignEnabled
      ? 'cart-page'
      : 'cms-page';
  },
  components: { SignInToStore },
  layout() {
    return useFeatureFlagsStore().isCoreRedesignEnabled
      ? 'defaultStatic'
      : 'cms/cmsDefault';
  },
  transition: 'fade',
  setup() {
    const {
      // TODO: isCoreRedesignEnabled - GLOBAL15-61059 remove after core redesign
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();

    if (!isCoreRedesignEnabled) {
      // full stop here as all the rest of the logic is
      // specific to statically-driven cart page
      return { isCoreRedesignEnabled };
    }

    return {
      isCoreRedesignEnabled,
    };
  },
};
