const defaultSettings = {
  displayAs: 'pictures',
  hideColorName: false,
  historyReplaceOnColorChange: false,
  lazy: false,
  isSwapCartItemModal: false,
  itemsCountLarge: null,
  itemsCountMedium: null,
  itemsCountSmall: null,
  saveVariationOnColorChange: false,
  showAllColors: false,
  showAlwaysAllColors: false,
  showDivider: false,
  showFullLabel: false,
  showNumeration: false,
  showSolidColors: false,
  splitColorsWatchForDiscount: false,
  _states: [],
};

const vansSettings = {
  ...defaultSettings,
  itemsCountLarge: 11,
  itemsCountMedium: 9,
  itemsCountSmall: 2,
  showAlwaysAllColors: true,
  showNumeration: true,
  _states: ['not-customsPDP'],
};

const thenorthfaceSettings = {
  ...defaultSettings,
  historyReplaceOnColorChange: true,
  itemsCountLarge: 11,
  itemsCountMedium: 8,
  itemsCountSmall: 5,
  saveVariationOnColorChange: true,
  showAlwaysAllColors: true,
  showDivider: true,
  showFullLabel: true,
  showSolidColors: true,
  splitColorsWatchForDiscount: true,
};

export const PdpProductColors = {
  vans: {
    US: vansSettings,
    CA: vansSettings,
  },
  thenorthface: {
    US: thenorthfaceSettings,
    CA: thenorthfaceSettings,
  },
};
