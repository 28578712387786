//
//
//
//
//

import useRootInstance from '@/shared/useRootInstance';
import { useCustoms } from '@vf/composables';
import useModal from '@/shared/useModal';
import Spinner from '@/components/loaders/Spinner.vue';
import { defineComponent, ref, onMounted } from '@vue/composition-api';

export default defineComponent({
  name: 'AddYourTouchPage',
  components: { Spinner },
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { customsDataByRecipe, customsImage } = useCustoms(root);
    const { setAdditionalData } = useModal();
    const recipe = root.$route.query.id;
    const isLoaded = ref(false);

    onMounted(async () => {
      try {
        const [image, data] = await Promise.all([
          customsImage({ recipe }),
          customsDataByRecipe(recipe),
        ]);
        setAdditionalData({ ...data, image });
        isLoaded.value = true;
      } catch (error) {
        root.$log.error(error);
      }
    });

    return {
      isLoaded,
    };
  },
});
