




















































































import { GiftOption, GiftOptionTranslations } from '@vf/api-contract';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfGiftOption',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<GiftOptionTranslations>,
      default: () => ({
        addGiftOptionCTA: 'Add Gift Option',
        itemGiftOptions: 'Gift Options',
        edit: 'Edit',
        remove: 'Remove',
        giftOptionTo: 'To:',
        giftOptionFrom: 'From:',
        giftOptionMessage: 'Message:',
        giftOptionGiftBox: 'Gift Box:',
      }),
    },
    /** Is gift option added to product flag */
    gift: {
      type: Boolean,
      default: false,
    },
    /** Display edit and remove actions flag */
    showActions: {
      type: Boolean,
      default: false,
    },
    giftBoxPrice: {
      type: String,
      default: '0$',
    },
    isGiftBoxAvailable: {
      type: Boolean,
      default: false,
    },
    isGiftBoxSelected: {
      type: Boolean,
      default: false,
    },
    giftOption: {
      type: Object as PropType<GiftOption>,
      default: () => ({
        to: '',
        from: '',
        message: '',
      }),
    },
  },
  setup(props) {
    const giftBoxLabel = computed(() => {
      if (!props.isGiftBoxAvailable) return '';
      return props.isGiftBoxSelected
        ? props.translations.giftOptionWithGiftBox?.replace(
            '{{price}}',
            props.giftBoxPrice
          )
        : props.translations.giftOptionWithoutGiftBox;
    });
    return {
      giftBoxLabel,
    };
  },
});
